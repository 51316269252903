import {type Bid} from 'features/adoppler';

import type {AdPodBeginEvent, AdPodFinishEvent} from 'types';

import type {VastUrlValue} from '@dailymotion/vast-client';
import type {NativeAd} from 'features/adoppler/types/native';
import type {FlagSet} from 'shared/hooks/use-launch-darkly-flags/types';
import type {VastParsedData} from 'types/vast-client';

/**
 * Make AdStart payload for the event
 * @param {string} adResponseId
 * @param {Bid[]} bids
 * @return {AdPodBeginEvent}
 */
export const getAdPodStartPayload = (adResponseId: string, bids: Bid[]) : AdPodBeginEvent => {
  return {
    action: 'begin',
    adPodId: adResponseId,
    duration: bids.reduce((acc, bid) => (acc + (bid?.dur || 0)), 0),
    creatives: bids.map((bid) => {
      return {
        creativeId: bid?.crid,
        duration: bid?.dur,
      };
    }),
  };
};

/**
 * Make AdFinish payload for the event
 * @param {string} adResponseId
 * @return {AdPodFinishEvent}
 */
export const getAdPodFinishPayload = (adResponseId: string) : AdPodFinishEvent => {
  return {
    action: 'finish',
    adPodId: adResponseId,
  };
};

interface ClickTrackingData {
  clickUrl: VastUrlValue['url'] | undefined;
  clickTrackingLink: NativeAd.Link;
  crId: string | undefined;
}

/**
 * Generates click tracking data object based on VAST data.
 * @param {Array} vastData The VAST data array.
 * @param {Array<string>} creativeAdClickTracker The creative click trackers.
 * @return {Object} The click tracking data object.
 */
export const generateClickTrackingData = (
  vastData: VastParsedData | undefined,
  creativeAdClickTracker: string[],
): ClickTrackingData => {
  const [mediaUrl, vastTracker] = vastData ?? [];

  const videoClickTrackingURLTemplates = vastTracker?.creative?.videoClickTrackingURLTemplates;

  const combinedArray = [
    ...creativeAdClickTracker,
    ...(vastTracker?.clickTrackingURLTemplates ?? []).map((temp) => temp.url),
    ...(videoClickTrackingURLTemplates ?? []).map((temp) => temp.url),
  ];

  const uniqueSet = new Set<string>();
  for (const item of combinedArray) {
    uniqueSet.add(item);
  }

  return {
    clickUrl: mediaUrl ? vastTracker?.clickThroughURLTemplate?.url : undefined,
    clickTrackingLink: {
      url: mediaUrl,
      clicktrackers: Array.from(uniqueSet),
    } as NativeAd.Link,
    crId: vastTracker?.creative?.id ?? undefined,
  };
};

/**
 * Determines whether to display closed captions based on the mute state and the closed captions status.
 *
 * @param {boolean} mute - The current mute state of the video. If `true`, the video is muted.
 * @param {'enabled' | 'disabled' | 'forced'} closedCaptionsStatus - The status of closed captions, which can be:
 *   - 'enabled': Captions are displayed only when the video is muted.
 *   - 'disabled': Captions are never displayed.
 *   - 'forced': Captions are always displayed, regardless of the mute state.
 *
 * @return {boolean} - Returns `true` if captions should be displayed based on the provided conditions, otherwise `false`.
 */
export const shouldDisplayCaptions = (
  mute: boolean,
  closedCaptionsStatus: FlagSet['closedCaptionsStatus'],
): boolean => {
  const captionLogic: Record<string, boolean> = {
    disabled: false,
    forced: true,
    enabled: mute,
  };

  return captionLogic[closedCaptionsStatus] ?? false;
};
