import {AdTypes} from 'features/adoppler';
import {logger as baseLogger} from 'shared/utils/logger';

import {getAdPodFromCache, getRandomItem} from './helpers';

import type {WrappedResponse} from 'features/adoppler';

const logger = baseLogger.child({tag: '[Adoppler AD Requestor]'});

/**
 * Form an empty call
 * @return {WrappedResponse}
 */
const getEmptyResponse = (): WrappedResponse => {
  return {type: AdTypes.DefaultTelly, response: null, dataUpdatedAt: Date.now()};
};

export const getInHouseAd = async (
  adType: AdTypes,
): Promise<WrappedResponse | void> => {
  try {
    const response = await getRandomItem(adType);
    if (!response) {
      logger.warn('No InHouseAd found as well.');
      return getEmptyResponse();
    }

    logger.info('InHouseAd fetched successfully', response);

    return {type: adType, response: response, dataUpdatedAt: Date.now()};
  } catch (error) {
    logger.warn('Error fetching InHouse Ad', error);
    return getEmptyResponse();
  }
};

export const getAd = async (
  adType: AdTypes,
): Promise<WrappedResponse | void> => {
  try {
    const parsedResponse = await getRandomItem(adType);
    logger.info('Received Adoppler response', parsedResponse);

    // INFO: For debugging change data to stub response here.
    return {type: adType, response: parsedResponse, dataUpdatedAt: Date.now()};
  } catch (error) {
    logger.warn('Error requesting Adoppler ad', error);
    return getEmptyResponse();
  }
};

export const getAdPod = async (
  duration: number,
): Promise<WrappedResponse | void> => {
  try {
    const parsedResponse = await getAdPodFromCache(duration);
    logger.info('Received AdPod response', parsedResponse, duration);

    return {type: AdTypes.AdPod, response: parsedResponse, dataUpdatedAt: Date.now()};
  } catch (error) {
    logger.warn('Error requesting Adoppler ad', error);
    return getEmptyResponse();
  }
};

export const getAvailableTypeInTheCache = async (
  adTypes: AdTypes[],
  waitLimit: number,
): Promise<AdTypes> => {
  try {
    const retryInterval = 100;
    let waitInterval = 0;

    return new Promise((resolve) => {
      const intervalId = setInterval(async () => {
        for (const adType of adTypes) {
          const cacheInstance = await caches.open(`cache-${adType}`.toLowerCase());
          const cacheItems = await cacheInstance.keys();
          if (cacheItems.length > 0) {
            clearInterval(intervalId);
            resolve(adType as AdTypes);
            break;
          }
        }

        if (waitInterval >= waitLimit) {
          clearInterval(intervalId);
          resolve(AdTypes.DefaultTelly);
        }

        waitInterval += retryInterval;
      }, retryInterval);
    });
  } catch (error) {
    logger.warn('Error getAvailableTypeInTheCache', error);
    return Promise.resolve(AdTypes.DefaultTelly);
  }
};
