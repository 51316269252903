import {VASTClient, VASTTracker, type VastCreativeLinear, type VastMediaFile} from '@dailymotion/vast-client';

import {TranscodingService} from 'shared/api/transcoding-service';
import {globalStorage} from 'shared/utils/globalStorage';
import {logger as baseLogger} from 'shared/utils/logger';

import type {VastParsedData} from 'types/vast-client';

const logger = baseLogger.child({tag: '[Parsing VAST]'});

/**
 * Parses a VAST (Video Ad Serving Template) URL and extracts relevant information.
 * @param {string} vastUrl - The URL of the VAST XML file.
 * @return {Promise<[string?, VASTTracker?, number?]>} A Promise that resolves to an array containing
 * a string representing the URL of the media file and an instance of VASTTracker if successful,
 * otherwise an empty array.
 */
export const parseVast = (vastUrl: string): Promise<VastParsedData> => {
  const vastClient = new VASTClient();
  const transcodingService = TranscodingService.getInstance();

  // now players wait only 5 sec before start of playing. so vast timeout is 4 sec
  return vastClient.get(vastUrl, {timeout: 4000, allowMultipleAds: true}).then(async (response) => {
    const ad = response.ads[0];
    if (!ad) {
      logger.error('No ads in response were found');
      throw new Error('No ads in response were found');
    }
    const linearCreative = ad.creatives.filter((creative) => creative.type === 'linear');

    if (linearCreative && linearCreative.length === 0) {
      logger.error('No linear creatives were found');
      throw new Error('No linear creatives were found');
    }

    const [creative] = linearCreative as VastCreativeLinear[];
    const vastTracker = new VASTTracker(vastClient, ad, creative);
    const mediaFiles = creative.mediaFiles;
    const mediaDuration = creative.duration * 1000;

    const {highPriorityMediaFiles, lowPriorityMediaFiles} = divideMediaByPriority(mediaFiles);
    logger.info('Media files priority', {highPriorityMediaFiles, lowPriorityMediaFiles});

    const mediaFile = findClosestByBitrate(
      highPriorityMediaFiles.length ? highPriorityMediaFiles : lowPriorityMediaFiles,
    );

    logger.info('Chosen media file', mediaFile);

    if (!mediaFile) {
      logger.error('No media files were found');
      throw new Error('No media files were found');
    }

    const vastPriority = globalStorage.adUnitConfig?.providers.gabriel.adoppler.config.vastPriority;
    const maxBitrate = vastPriority?.maxBitrate ?? Infinity;
    const maxResolution = vastPriority?.maxResolution || {width: 1280, height: 720};
    const highestResolutionMediaFile = findHighestResolutionMediaFile(mediaFiles);

    // Check if media file exceeds max bitrate or max resolution
    if (mediaFile.bitrate > maxBitrate
        || mediaFile.width > maxResolution.width
        || mediaFile.height > maxResolution.height) {
      const urlToTranscode = highestResolutionMediaFile?.fileURL || mediaFile.fileURL;
      const transcodedFileURL = await transcodingService.getTranscodedVideoUrl(urlToTranscode);

      return [transcodedFileURL, vastTracker, mediaDuration] as VastParsedData;
    }

    return [mediaFile.fileURL, vastTracker, mediaDuration, highestResolutionMediaFile?.fileURL] as VastParsedData;
  });
};

/**
 * Finds the VastMediaFile object with the highest resolution.
 *
 * @param {VastMediaFile[]} mediaFiles - An array of VastMediaFile objects.
 * @return {VastMediaFile|null} The VastMediaFile object with the highest resolution, or null if no valid object is found.
 */
export function findHighestResolutionMediaFile(mediaFiles: VastMediaFile[]): VastMediaFile | null {
  if (!Array.isArray(mediaFiles) || mediaFiles.length === 0) {
    return null;
  }

  return mediaFiles.reduce((highest, mediaFile) => {
    if (!mediaFile || typeof mediaFile.width !== 'number' || typeof mediaFile.height !== 'number') {
      return highest;
    }

    const currentResolution = mediaFile.width * mediaFile.height;
    const highestResolution = highest.width * highest.height;

    if (currentResolution > highestResolution) {
      return mediaFile;
    }

    return highest;
  });
}

/**
 * Finds the closest VastMediaFile in an array based on the difference in height
 * between the media file and the current viewport height.
 *
 * @param {VastMediaFile[]} mediaFiles - An array of VastMediaFile objects to search.
 * @return {VastMediaFile|null} - The VastMediaFile that is closest to the current viewport.
 */
export function findClosestByHeightMediaFile(mediaFiles: VastMediaFile[]): VastMediaFile | null {
  const viewport = window.innerHeight;

  if (!Array.isArray(mediaFiles) || mediaFiles.length === 0) {
    // Handle the case when mediaFiles is not an array or is empty
    return null;
  }

  return mediaFiles.reduce((closest, mediaFile) => {
    if (!mediaFile || typeof mediaFile.height !== 'number') {
      // Handle the case when mediaFile is not valid
      return closest;
    }

    const heightDifference = Math.abs(mediaFile.height - viewport);
    const closestDifference = Math.abs(closest.height - viewport);

    if (heightDifference <= closestDifference) {
      return mediaFile;
    }

    return closest;
  });
}

/**
 * Finds the VastMediaFile object with the highest bitrate that is less than or equal to 70% of the estimated network download speed.
 *
 * @param {VastMediaFile[]} mediaFiles - An array of VastMediaFile objects.
 * @return {VastMediaFile|null} The VastMediaFile object with the highest bitrate that meets the criteria, or null if no valid object is found.
 */
export function findClosestByBitrate(mediaFiles: VastMediaFile[]): VastMediaFile | null {
  logger.info('Connection params', navigator.connection);
  const vastPriority = globalStorage.adUnitConfig?.providers.gabriel.adoppler.config.vastPriority;

  const speedKbs = navigator.connection?.downlink || 0;
  if (!Array.isArray(mediaFiles) || mediaFiles.length === 0) {
    // Handle the case when mediaFiles is not an array or is empty
    return null;
  }

  // Use 50% of the estimated network download speed as a safety margin to reduce the likelihood of buffering or playback issues
  const bitrate = Math.min(vastPriority?.maxBitrate ?? Infinity, speedKbs * 1024 * 0.3);

  return mediaFiles.sort((a, b) => a.bitrate - b.bitrate).reduce((closest, mediaFile) => {
    if (mediaFile.bitrate <= bitrate && mediaFile.bitrate > closest.bitrate) {
      return mediaFile;
    }

    return closest;
  });
}

/**
 * Divides an array of VastMediaFile objects into high priority and low priority media files
 * based on resolution and MIME type.
 *
 * @param {VastMediaFile[]} mediaFiles - An array of VastMediaFile objects.
 * @return {{
 *   highPriorityMediaFiles: VastMediaFile[],
 *   lowPriorityMediaFiles: VastMediaFile[]
 * }} An object containing two arrays: highPriorityMediaFiles and lowPriorityMediaFiles.
 */
export const divideMediaByPriority = (mediaFiles: VastMediaFile[]): {
  highPriorityMediaFiles: VastMediaFile[],
  lowPriorityMediaFiles: VastMediaFile[]
} => {
  const vastPriority = globalStorage.adUnitConfig?.providers.gabriel.adoppler.config.vastPriority;
  const maxResolution = vastPriority?.maxResolution || {width: 1280, height: 720};

  /**
   * Determines if a media file is considered high priority based on its MIME type and resolution.
   *
   * @param {VastMediaFile} mediaFile - The VastMediaFile object to evaluate.
   * @param {Object} maxResolution - An object containing the maximum allowed width and height.
   * @param {number} maxResolution.width - The maximum allowed width.
   * @param {number} maxResolution.height - The maximum allowed height.
   * @return {boolean} True if the media file is considered high priority, false otherwise.
   */
  const isHighPriorityMedia = (mediaFile: VastMediaFile, maxResolution: {width: number, height: number}): boolean =>
    mediaFile.mimeType !== 'video/x-flv'
    && (mediaFile.width <= maxResolution.width && mediaFile.height <= maxResolution.height);

  return {
    highPriorityMediaFiles: mediaFiles.filter((mediaFile) => isHighPriorityMedia(mediaFile, maxResolution)),
    lowPriorityMediaFiles: mediaFiles.filter((mediaFile) => !isHighPriorityMedia(mediaFile, maxResolution)),
  };
};
