import {useEffect} from 'react';

import {TELLY_EVENT} from 'shared/constants';
import {logger} from 'shared/utils';

/**
 * Custom hook to handle events and extract properties of type T from them.
 *
 * @template T - The type of properties to extract from the event.
 * @param {string} eventType The type of event. Default TELLY_EVENT
 * @param {func} handler
 */
function useCustomEventHandler<T>(
  eventType: string = TELLY_EVENT,
  handler: (eventDetails: T) => void,
): void {
  useEffect(() => {
    const handleEvent = (event: Event): void => {
      const properties = (event as unknown as {detail: T}).detail;

      logger.info('Got event from SDK:', {eventType, properties});
      handler(properties);
    };
    window.addEventListener(eventType, handleEvent);

    return () => window.removeEventListener(eventType, handleEvent);
  }, [eventType, handler]);
}

export default useCustomEventHandler;
