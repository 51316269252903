import {useEffect, useState} from 'react';

import Joi from 'joi';
import {initialize} from 'launchdarkly-react-client-sdk';

import {meteEnvConfig} from 'config';
import {useAppContext} from 'context';
import {logger as baseLogger} from 'shared/utils/logger';

import {flagDetailChangedInspector, mapToContextsFrom} from './helpers';
import validate from './validator';

import type {FlagSet} from './types';

const CACHE_DURATION_MS = 10 * 60 * 1000;

const logger = baseLogger.child({tag: '[Launch Darkly Flags]'});
/**
 * Joi schema for validating an object that conforms to the `FlagSet` interface.
 *
 * The schema specifies that the object must include certain required keys,
 * each with a specific type and set of valid values.
 *
 * - If the object contains any additional keys not defined in the schema,
 *   an error will be thrown.
 * - If any required keys are missing from the object,
 *   an error will be thrown.
 *
 * This schema ensures strict adherence to the defined structure and prevents
 * any deviations or extra fields in the object.
 *
 * @type {Joi.ObjectSchema<FlagSet>}
 */
const schema: Joi.ObjectSchema<FlagSet> = Joi.object<FlagSet>({
  closedCaptionsStatus: Joi.string().valid('enabled', 'disabled', 'forced').required(),
}).required().strict();

type LDFlagSet = FlagSet;

/**
 * Custom hook to retrieve and validate feature flags using LaunchDarkly.
 *
 * This hook retrieves the feature flags using the `useFlags` hook from the LaunchDarkly SDK
 * and validates them against the predefined schema.
 *
 * If the flags do not conform to the schema, an error will be logged in development mode.
 *
 * @return {LDFlagSet} The validated flags object.
 *
 * @throws {Error} If the flags object contains invalid keys or values,
 *                 or if required keys are missing.
 */
function useLaunchDarklyFlags(): LDFlagSet {
  const clientSideID = meteEnvConfig.launchdarklyClientSideID;

  const {deviceProperties} = useAppContext();

  const [flags, setFlags] = useState<LDFlagSet | object>({});

  useEffect(() => {
    if (!deviceProperties) return;
    if (!clientSideID) {
      logger.warn('LaunchDarkly clientSideID is required');
      return;
    }

    const context = mapToContextsFrom(deviceProperties, meteEnvConfig.ads.adUnit);

    const ldClient = initialize(clientSideID, context, {
      bootstrap: 'localStorage',
      diagnosticOptOut: true,
      streaming: true,
      flushInterval: CACHE_DURATION_MS,
      inspectors: [flagDetailChangedInspector(logger)],
    });

    const initLdClient = async () => {
      await ldClient?.waitForInitialization(5).then(async () => {
        await ldClient?.waitUntilReady();
        const flags = ldClient.allFlags() as LDFlagSet;
        const {error, value} = validate<LDFlagSet>(flags as LDFlagSet, schema);
        if (error) {
          logger.warn(`Invalid flags returned:`, error.message);
        }
        setFlags(value || {});
      });
    };

    initLdClient();
  }, [clientSideID, deviceProperties, setFlags]);

  return flags as FlagSet;
}

export default useLaunchDarklyFlags;
