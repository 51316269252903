import {useEffect, useState} from 'react';

import {meteEnvConfig} from 'config';
import {useAdUnitConfigContext, useAppContext} from 'context';
import freestarManager from 'shared/utils/freestar-ads';
import {logger as baseLogger} from 'shared/utils/logger';

import {getGoogleConfigData, getKeyByValue} from './helpers';

import type {SizeChangeEvent, UseFreestarReturnType} from './types';

const logger = baseLogger.child({tag: '[Freestar slot]'});

/**
 * Creates a Freestar slot with the specified size and adds it to the page.
 * @return {UseFreestarReturnType}
 */
export function useFreestar(): UseFreestarReturnType {
  const {deviceProperties} = useAppContext();
  const {adUnitConfig} = useAdUnitConfigContext();
  const [renderedSize, setRenderedSize] = useState<string | number[] | null>(null);

  const isFreeStarEnabled = ['EVE_FULLSCREEN_UNIT', '3P_SMART_SCREEN_AD_UNIT'].includes(meteEnvConfig.ads.adUnit);
  const featureFlags = adUnitConfig.features;

  logger.info('Render Freestar component', deviceProperties);

  const handleSizeChange = (event: CustomEvent<SizeChangeEvent>) => {
    setRenderedSize(event.detail.size);
  };

  useEffect(() => {
    if (!deviceProperties || !isFreeStarEnabled || !adUnitConfig) return;

    const initializeFreeStar = async () => {
      try {
        const freestarParams = getGoogleConfigData(adUnitConfig, deviceProperties);
        await freestarManager.addHashedEmailAndSlots(freestarParams);
        freestarManager.addSizeChangedEventListener(handleSizeChange);
        logger.info('Freestar component initialized', freestarParams);
      } catch (error) {
        logger.warn('Failed to initialize Freestar');
        throw error;
      }
    };

    initializeFreeStar().catch(() => {});

    return () => {
      logger.info('Fallback Ad Load Timeout Canceled');
      freestarManager.removeSizeChangedEventListener(handleSizeChange);
      freestarManager.destroy();
    };
  }, [adUnitConfig, deviceProperties, isFreeStarEnabled]);

  return {
    size: getKeyByValue(renderedSize),
    defaultQabElement: featureFlags?.showButtonB?.enable ? 'button-b' : 'default-ad',
    crid: freestarManager.getCreativeId(),
  };
}
