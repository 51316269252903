import React, {useEffect} from 'react';

import * as Sentry from '@sentry/react';

import {meteEnvConfig} from 'config';

const IGNORING_RULES = {
  freestar: ['prebid-analytics', 'Freestar'],
};

const withSentry = (FC: React.FC) =>
  (function() {
    useEffect(() => {
      if (meteEnvConfig.logger.sentry.isEnabled) {
        Sentry.init({
          dsn: 'https://614275375afc426380ac4c9dd01ff81c@o1290113.ingest.sentry.io/4505359350497280',
          environment: meteEnvConfig.environment,
          normalizeDepth: 20,
          denyUrls: [
            /pageFold\/ftpagefold/,
          ],
          ignoreErrors: [
            ...IGNORING_RULES.freestar,
          ],
          beforeSend: (event) => {
            // For local dev, send Sentry events to the browser's console and prevent sending them to the Sentry service
            if (window.location.hostname === 'localhost' || !navigator.onLine) {
              console.log('Sentry event:', event);
              return null;
            }

            // Iterate over IGNORING_RULES to filter out exceptions
            if (event.exception && event.exception.values) {
              const exceptionMessage = event.exception.values[0].value || '';
              for (const rule of IGNORING_RULES.freestar) {
                if (exceptionMessage.includes(rule)) {
                  return null;
                }
              }
            }

            return event;
          },
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
              // Specify the application keys that you specified in the Sentry bundler plugin
              filterKeys: ['gabriel-application-key'],

              // Defines how to handle errors that contain third party stack frames.
              // Possible values are:
              // - 'drop-error-if-contains-third-party-frames'
              // - 'drop-error-if-exclusively-contains-third-party-frames'
              // - 'apply-tag-if-contains-third-party-frames'
              // - 'apply-tag-if-exclusively-contains-third-party-frames'
              behaviour: 'drop-error-if-contains-third-party-frames',
            }),
          ],
          tracePropagationTargets: ['localhost', 'https://nbijy88ez0gw.staging.teevee.info/'],
          sampleRate: 0.05,
          tracesSampleRate: 0.05,
          replaysSessionSampleRate: 0.005,
          replaysOnErrorSampleRate: 0.05,
        });
      }
    }, []);

    return <FC />;
  });

export default withSentry;
