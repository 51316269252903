// export type ImportMetaEnvLocal = ImportMetaEnv;

import type {ImportMetaEnvLocal} from '../../vite-env';

// Otherwise import.meta.env will use ImportMetaEnv from the system.
// That is due the exporting an ImportMetaEnvLocal type from the vite-env.d.ts
const metaEnv = import.meta.env as ImportMetaEnvLocal;

// eslint-disable-next-line require-jsdoc
function isMetaEnv<T extends keyof ImportMetaEnv>(key: ImportMetaEnv[T]): boolean {
  return key in import.meta.env;
}

/**
 * Parses an environment variable.
 *
 * If the variable exists in `import.meta.env`, the function tries to parse it as JSON.
 * If parsing fails due to a syntax error, the function returns the plain value of the variable.
 * If parsing fails due to other errors, the function throws the error.
 * If the variable does not exist in `import.meta.env`, the function returns the provided default value.
 *
 * @template T - The expected type of the environment variable value.
 * This influences the return type and is used to cast the value of the environment variable (if it exists).
 *
 * @param {string} envVarName - The name of the environment variable to parse.
 *
 * @param {string} defaultValue - The default value to return if the environment variable is not set.
 *
 * @return {string} - The parsed environment variable value if it exists and is valid JSON,
 * the plain value if it exists and is not valid JSON (syntax error during parsing),
 * or `defaultValue` if the environment variable is not set.
 *
 * @throws {Error} - Throws an error if parsing fails due to reasons other than a syntax error.
 */
function parseEnvVar<T extends keyof ImportMetaEnvLocal>(
  envVarName: T,
  defaultValue: ImportMetaEnvLocal[T],
): ImportMetaEnvLocal[T] {
  if (!isMetaEnv(envVarName)) return defaultValue;

  try {
    return JSON.parse(metaEnv[envVarName] as keyof ImportMetaEnvLocal);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return metaEnv[envVarName];
    }

    throw e;
  }
}

const config = {
  ads: {
    // If you want to force a Gabriel show only one type of ads, you can define here required ads type
    types: parseEnvVar('VITE_ADS__TYPES', undefined),
    // ad strategy
    strategy: parseEnvVar('VITE_AD__STRATEGY', undefined),
    // ad unit. Possible variants: EVE_FULLSCREEN_UNIT ADAM_SCREENSAVER_VIDEO_UNIT ADAM_SCREENSAVER_HOUSE_AD_UNIT_UP ADAM_SCREENSAVER_HOUSE_AD_UNIT_DOWN
    adUnit: parseEnvVar('VITE_AD__UNIT', 'EVE_FULLSCREEN_UNIT'),
    // google publisher tag sizes
    gpt: {
      targeting: parseEnvVar('VITE_GPT__TARGETING', undefined),
      slotId: parseEnvVar('VITE_GPT__SLOT_ID', '22982538162'),
      eagerLoading: parseEnvVar('VITE_GPT__EAGER_LOADING', false),
    },
    video: {
      mute: parseEnvVar('VITE_VIDEO__MUTE', 1),
      autoplay: parseEnvVar('VITE_VIDEO__AUTOPLAY', 1),
    },
    debug: {
      tellySdkClickEvents: parseEnvVar('VITE_DEBUG__TELLY__SDK__CLICK__EVENTS', false),
    },
  },
  device: {
    // For development purposes only, mock of device information to allow making actual AdRequests
    mock: parseEnvVar('VITE_DEVICE__MOCK', null),

    // ratio between actual dimensions and design
    scaleFactor: parseEnvVar('VITE_DEVICE__SCALING_FACTOR', 1.0),
  },
  /**
   * Environments tell you where an error occurred, whether that's in your production system, your staging server,or elsewhere.
   * Sentry automatically creates an environment when it receives an event with the environment parameter set.
   * @see https://docs.sentry.io/platforms/javascript/configuration/environments/
   */
  environment: parseEnvVar('VITE_APP_ENVIRONMENT', 'development'),
  launchdarklyClientSideID: parseEnvVar('VITE_LD_CLIENT_SIDE_ID', '66be295301e6a30f75d0e0ff'),
  logger: {
    // configuration for Sentry service (https://sentry.io)
    sentry: {
      // Is that service enabled in the current environment or not
      isEnabled: parseEnvVar('VITE_LOGGER__SENTRY__ISENABLED', true),
      /**
       * The Sentry SDKs have two configuration options to control the volume of transactions sent to Sentry, allowing you to take a representative sample
       * Uniform sample rate (tracesSampleRate):
       * Provides an even cross-section of transactions, no matter where in your app or under what circumstances they occur.
       * @see https://docs.sentry.io/platforms/javascript/configuration/sampling/#configuring-the-transaction-sample-rate
       */
      tracesSampleRate: parseEnvVar('VITE_LOGGER__SENTRY__TRACESSAMPLERATE', 1.0),
      /**
       * The sample rate for replays that begin recording immediately and last the entirety of the user's session.
       * @see https://docs.sentry.io/platforms/javascript/guides/capacitor/session-replay/#sampling
       */
      replaysSessionSampleRate: parseEnvVar('VITE_LOGGER__SENTRY__REPLAYSSESSIONSAMPLERATE', 0.1),
      /**
       * The sample rate for replays that are recorded when an error happens. This type of replay will record up to a minute of events prior to the error and continue recording until the session ends.
       * @see https://docs.sentry.io/platforms/javascript/guides/capacitor/session-replay/#sampling\
       */
      replaysOnErrorSampleRate: parseEnvVar('VITE_LOGGER__SENTRY__REPLAYSERRORSAMPLERATE', 1.0),
    },
  },
};

export default config;
