import Joi from 'joi';

import {logger as baseLogger} from 'shared/utils/logger';

import {type OpenAPIDocument, generateJoiFromOpenAPI} from './converter';

const logger = baseLogger.child({tag: '[ACR Validation Generation]'});

export const initAcrValidation = async () => {
  const defaultACRPayloadSchema = Joi.object({
    id: Joi.string().required(),
    type: Joi.string().optional(),
    typeMetadata: Joi.alternatives().try(
      Joi.object({
        brandId: Joi.string().optional(),
        categoryId: Joi.string().optional(),
        iabCode: Joi.string().optional(),
      }),
      Joi.any().allow(null),
    ).optional(),
    blacklist: Joi.boolean().optional(),
  }).unknown();

  try {
    const module = await import('./cloud-api-specs/json-schemas/acr-events/draft/openapi.yaml');
    const acrOpenApi = module.default;
    const acrValidation = generateJoiFromOpenAPI(acrOpenApi as OpenAPIDocument);
    return acrValidation?.MatchPayload ?? defaultACRPayloadSchema;
  } catch (e) {
    logger.warn('Cannot generate joi validation from open api scheme', e);
  }

  return defaultACRPayloadSchema;
};
