import Joi from 'joi';

import {logger as baseLogger} from 'shared/utils/logger';

import type {AdEventData, AdEventMap, OperationType} from './types';

const logger = baseLogger.child({tag: '[ADS Analytics fields Validator]'});

export const requiredFieldsSchema = {
  Request: {
    ad_placement_id: Joi.string().required(),
    ad_request_id: Joi.string().required(),
    ad_server: Joi.string().valid('gam', 'elemental').required(),
    ifa_id: Joi.string().required(),
    ad_unit_id: Joi.string().optional(),
  } satisfies {[key in keyof AdEventMap['Request']]: Joi.StringSchema<string>},
  Impression: {
    ad_request_id: Joi.string().required(),
    ad_server: Joi.string().valid('gam', 'elemental').required(),
    advertiser_name: Joi.string().optional(),
    creative_id: Joi.string().required(),
    creative_permutation_type: Joi.string().optional(),
    bid_request_id: Joi.string().optional(),
    ifa_id: Joi.string().required(),
    ad_unit_id: Joi.string().optional(),
  } satisfies {[key in keyof AdEventMap['Impression']]: Joi.StringSchema<string>},
  Interaction: {
    ad_request_id: Joi.string().required(),
    ifa_id: Joi.string().required(),
    creative_id: Joi.string().required(),
    creative_permutation_type: Joi.string().optional(),
  } satisfies {[key in keyof AdEventMap['Interaction']]: Joi.StringSchema<string>},
  Error: {
    ad_error_code: Joi.string().required(),
    ad_error_log_response: Joi.string().required(),
    ad_error_source: Joi.string().valid('gam', 'elemental'),
    ad_request_id: Joi.string().required(),
    ifa_id: Joi.string().required(),
    ad_unit_id: Joi.string().optional(),
  } satisfies {[key in keyof AdEventMap['Error']]: Joi.StringSchema<string>},
  AdBarkerData: {
    channel_id: Joi.string().required(),
    episode_id: Joi.string().required(),
    block_id: Joi.string().required(),
    ad_placement_id: Joi.string().required(),
    eventType: Joi.string().required().valid('start', 'firstQuartile', 'midpoint', 'thirdQuartile', 'complete'),
  } satisfies {[key in keyof AdEventMap['AdBarkerData']]: Joi.StringSchema<string>},
};

/**
 * Validates ad event data against a specific event type and its required fields.
 * @param {AdEventData<T>} data - The data to validate.
 * @param {OperationType} eventName - The type of ad event.
 * @param {string[]} fields - The required fields for the specified event type.
 * @return {AdEventData<T>} - The validation result.
 */
export function validateEvent<T extends OperationType>(
  data: AdEventData<T>,
  eventName: OperationType,
  fields: string[],
): Joi.ValidationResult<AdEventData<T>> {
  let schema = Joi.object();

  if (requiredFieldsSchema[eventName]) {
    schema = schema.keys(
      fields.reduce((accumulator, field) => {
        accumulator[field as keyof AdEventData<T>] =
          requiredFieldsSchema[eventName][field as keyof AdEventMap[OperationType]] ?? Joi.any();
        return accumulator;
      }, {} as Record<keyof AdEventData<T>, Joi.Schema>),
    );
  }

  const validatedData = schema.validate(data, {abortEarly: false});

  const {error, warning} = validatedData;

  if (error) {
    logger.warn(`Invalid data for event ${eventName}. Missing required fields in ${JSON.stringify(data)}`, error);
  }

  if (warning) {
    logger.warn(`Data validation warning for event ${eventName}`, warning);
  }

  return validatedData;
}
