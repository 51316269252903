import Joi from 'joi';

import {AdTypes} from 'features/adoppler';
import {logger as baseLogger} from 'shared/utils/logger';

import type {AdConfig, Gabriel} from 'types';

const logger = baseLogger.child({tag: '[Ad Configuration Validator]'});

/**
 * Validates the ad config from the bootstrap service or ad information object using a schema.
 *
 * @param {AdConfig} adConfig - The ad configuration to validate.
 * @return {Joi.ValidationResult<AdConfig>}.
 */
export function validateAdConfig(adConfig: AdConfig | undefined): Joi.ValidationResult<AdConfig> {
  const adConfigSchema = Joi.object<AdConfig>({
    features: Joi.object().pattern(Joi.string(), Joi.object({
      enable: Joi.boolean().required().description('Indicates whether the feature flag is enabled.'),
    }).unknown(true)),
    strategy: Joi.string().valid('gabriel', 'google', 'google-first').required(),
    slot: Joi.object<AdConfig['slot']>({
      height: Joi.number().required(),
      width: Joi.number().required(),
    }).required(),
    providers: Joi.object<AdConfig['providers']>({
      googleNativeSdk: Joi.object({}),
      gabriel: Joi.object<Gabriel>({
        adoppler: Joi.object<Gabriel['adoppler']>({
          strategy: Joi.string().valid(
            'adoppler-multibid-strategy',
            'adoppler-barker-strategy',
            'adoppler-random-weighted-strategy',
            'adoppler-strategy',
          ).required(),
          config: Joi.object({
            fetchInterval: Joi.number().required(),
            weightConfig: Joi.object().pattern(
              Joi.string().valid(...Object.values(AdTypes).map((type) => String(type))),
              Joi.number(),
            ),
            vastPriority: Joi.object({
              maxResolution: Joi.object({
                width: Joi.number().required(),
                height: Joi.number().required(),
              }),
              maxBitrate: Joi.number(),
            }),
            elementalEnv: Joi.string(),
          }).required().unknown(),
          template: Joi.string().valid('default', 'eve-screen-fullscreen').required(),
          adTypes: Joi
            .array<AdTypes[]>()
            .items(Joi.valid(
              ...Object.values(AdTypes),
            ))
            .required(),
          fallbackAdType: Joi.string().valid(501, 502, 506, 509, 511, 'Video'),
          cache: Joi.object().pattern(
            Joi.string(),
            Joi.object({
              retryInterval: Joi.number().description('Interval for retry logic'),
              capacity: Joi.number().description('capacity'),
              maxAge: Joi.number().description('Cache lifetime'),
            }).description('Cache workers configuration'),
          ),
        }).required().unknown(),
        googleTagManager: Joi.object({
          scriptURLs: Joi.array().items(Joi.string().uri()),
          slotPlacementId: Joi.string(),
          placementName: Joi.string(),
          slotSizes: Joi.array().items(Joi.array().items(Joi.number()).length(2)),
          slotId: Joi.string().allow('')
            .description('Google Tag Manager slot ID for the ad unit')
            .example('/22982538162/EVE_FULLSCREEN_UNIT/staging'),
          targeting: Joi.object().pattern(
            Joi.string(),
            Joi.alternatives().try(
              Joi.string(),
              Joi.array().items(Joi.string()),
            ),
          ).optional().description('Object containing targeting parameters'),
          eagerLoading: Joi.boolean().description('Preload all Freestar ad scripts into the DOM eagerly'),
          displayTiming: Joi.object({
            minDisplayTiming: Joi.number().required().description('Min display timing in ms'),
            maxDisplayTiming: Joi.number().required().description('Max display timing in ms'),
          }),
        }).allow(null),
      }).required(),
    }),
  });

  const validationResult = adConfigSchema.validate(adConfig, {abortEarly: false, convert: false});

  const {error} = validationResult;

  if (error) {
    logger.error('Ad Configuration validation error', error);
  }

  return validationResult;
}
