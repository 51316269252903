import {parseVast} from 'features/vast-tracker/service';
import {logger as baseLogger} from 'shared/utils/logger';

import type {UseAdReturnType} from 'types';

import type {VastParsedData} from 'types/vast-client';

const logger = baseLogger.child({tag: '[prefetch Component]'});

Element.prototype.remove = function() {
  this.parentElement?.removeChild(this);
};

NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
  for (let i = this.length - 1; i >= 0; i--) {
    this[i]?.parentElement?.removeChild(this[i]);
  }
};

export const prefetchAdPod = async ({adSettings}: UseAdReturnType) => {
  const adUrls = adSettings.map((n) => n.adUrls).flat(1);
  const vasts = await getVast(adUrls);

  return vasts
    .filter(({status}) => status === 'fulfilled')
    .map((p) => (p as PromiseFulfilledResult<VastParsedData>).value);
};

export const getVast = async (adUrls: string[]) => {
  const promises = adUrls.map(async (adUrl) => {
    return parseVast(adUrl).then((vastData) => {
      // append vast data only for current response id
      if (vastData[0]) {
        doPrefetch(vastData[0]);
      }
      return vastData;
    });
  });

  const results = await Promise.allSettled(promises);
  const allRejected = results.every((result) => result.status === 'rejected');
  if (allRejected) {
    logger.warn('Vast HTTP Error handled', results);
    return [];
  }
  return results;
};

/**
   * Make a 'preload' tag to put file into the HTTP cache
   * @param {string} videoURL
   */
function doPrefetch(videoURL: string) {
  const link = document.createElement('link');
  link.setAttribute('data-type', 'adpod');
  link.as = 'video/mp4';
  link.rel = 'prefetch';
  link.onload = () => {
    link.remove();
  };
  link.href = videoURL;
  document.head.appendChild(link);
}
