
import type {AdUnitType, DeviceInfo} from 'types';

import type {LDInspectionFlagDetailChangedHandler, LDMultiKindContext} from 'launchdarkly-react-client-sdk';
import type pino from 'pino';

/**
 * Maps the version information from the device properties into a structured object format.
 *
 * This function takes the `versions` property from the provided `deviceProperties` object,
 * iterates over its entries, and creates a new object where each key corresponds to a version
 * identifier, and each value is an object containing the version string under the `key` property.
 *
 * @param {DeviceInfo} deviceProperties - The device properties containing version information.
 * @param {AdUnitType} adUnit - The ad unit type.
 * @return {Record<string, {key: string}>} A record object where each key is a version identifier
 * and the value is an object containing the version string under the `key` property.
 */
function mapToContextsFrom(deviceProperties: DeviceInfo, adUnit: AdUnitType): LDMultiKindContext {
  const versions: Record<string, {key: string}> = {};

  for (const [key, value] of Object.entries(deviceProperties.versions as object)) {
    versions[key] = {
      key: value,
    };
  }

  const context: LDMultiKindContext = {
    kind: 'multi',
    ifaValue: {
      key: deviceProperties?.ad_info.ifa,
      lmt: deviceProperties?.ad_info.lmt,
      ifa_type: deviceProperties?.ad_info.ifa_type,
    },
    gabrielAppVersion: {
      key: __APP_VERSION__,
    },
    adUnit: {
      key: adUnit,
    },
    ...versions,
  };

  return context;
}

/**
 * Updates the flag value and logs the change.
 *
 * This function is intended to handle a feature flag changes.
 * It logs the new value of the flag and can be extended to include logic for caching the flag.
 *
 * @param {string} flagKey - The key of the feature flag that has changed.
 * @param {Record<string, unknown>} value - The new value of the feature flag.
 * @param {pino.Logger<never>} logger - The logger from parent.
 */
function updateFlag(flagKey: string, value: Record<string, unknown>, logger: pino.Logger<never>) {
  logger.info(`Flag "${flagKey}" changed:`, value);
}

const flagDetailChangedInspector = (logger: pino.Logger<never>) => ({
  type: 'flag-detail-changed',
  name: 'FlagDetailChangeInspector',
  synchronous: true,
  method: (flagKey, detail) => {
    updateFlag(flagKey, detail.value, logger);
  },
} as LDInspectionFlagDetailChangedHandler);

export {
  mapToContextsFrom,
  updateFlag,
  flagDetailChangedInspector,
};
